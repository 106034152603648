
.drawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2rem;
  }
  
  .map-div {
    max-width: 700px;
    align-self: center;
    & img {
      width: 100%;
      height: 100%;
    }
  }
  
  .close-icon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  