.hero-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .hero-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  }
}
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  padding: 3rem;
}

.imgWrapper {
  max-width: 30rem;
}

.imgWrapper img {
  height: 100%;
  width: 100%;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  line-height: 2.5rem;
}

.hero-subtext {
  font-size: 28px;
}
