.service-selection-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
}
.service-tile-info {
    display: flex;
    flex-direction: column;
    gap: .5rem; 
}

.service-tile {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    /* border-top: 1px solid var(--primary-light); */
    border: 2px solid var(--grey-border);
}

.service-tile-active {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    /* border-top: 1px solid var(--primary-light); */
    border: 2px solid var(--primary-main);
}

.service-img {
    max-width: 25rem;
    & img {
        width: 100%;
        height: 100%;
    }
}
