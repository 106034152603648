.reservation-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 0.0625rem solid var(--grey-border);
  padding: 8px 0;
}

.reservation-btn-group {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.form-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.personal-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  transition: 0.2s ease ease-in-out;
}

.personal-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
