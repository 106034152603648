.ratings-container {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background-color: green; */
}
.review-tile {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  gap: 2rem;
  background-color: white;
  filter: drop-shadow(1px 1px 10px var(--grey-border));
  width: 100%; /* Full width of parent */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;     /* Center content vertically */
  box-sizing: border-box; 
}

.rating-details {
  display: flex;
  flex-direction: column;
}

.ratings-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text);
  gap: 0.5rem;
  align-items: flex-start;
}

.overall-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* background-color: orange; */
  width: 100%;

}

.review-description {
  font-size: 1.25rem;
}

.rating-div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}

/* Parent container (carousel) is a flex container */
.reviews-carasoul {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers content horizontally */
  align-items: stretch; /* Aligns content at the top */
  width: 100%;
  height: auto; /* Height will adapt to the tallest element */
  /* background-color: red; */
}

/* Make sure all review slides expand to the full width */
.stack-container {
  width: 100%;
  height: 100%;
}

/* Each review-slide will take 100% of the parent's width */
.review-slide {
  width: 100%; /* Full width of parent */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;     /* Center content vertically */
  box-sizing: border-box; /* Ensure padding doesn’t affect width */
}

.review-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}


.drawer-header {
  display: flex;
  padding: 2rem;
  align-items: flex-start;
  justify-content: space-between;
}
.list {
  padding: 2rem;
  display: flex;
}

.list-content {
  display: flex; 
  flex-direction: column;
  padding: 2rem; 
  gap: 1rem;
}