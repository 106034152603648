.event-duration-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.time-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem 0;
  /* flex-direction: column; */
  gap: 1rem;
}

.time-estimates {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
}

.time {
  font-size: 1.25rem;
  /* color: var(--primary-main) */
  font-weight: 200;
}
.caption {
  font-size: 0.875rem;
}

.time-input {
  border: 1px solid var(--text);
  padding: 1rem 0;
  color: var(--primary-main);
  font-family: 'Cabin Condensed';
  font-size: 1.25rem;
  border: none;
}

.time-label {
  font-weight: bold;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type='time']::-webkit-datetime-edit-hour-field {
  border-radius: 15%;
  /* padding: 19px 13px; */
}

/* Minute */
input[type='time']::-webkit-datetime-edit-minute-field {
  border-radius: 15%;
  /* padding: 19px 13px; */
}

/* AM/PM */
input[type='time']::-webkit-datetime-edit-ampm-field {
  border-radius: 15%;
  color: var(--primary-main);
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type='time']::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type='time']::-webkit-inner-spin-button {
  display: none;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
