.info-message {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem;
  border-left: 5px solid var(--info);
  background-color: var(--info-bg);
  color: var(--info);
}

.error {
  border-left: 5px solid var(--error);
  background-color: var(--error-bg);
  color: var(--error);
}

.success {
  border-left: 5px solid var(--primary-main);
  background-color: var(--success-bg);
  color: var(--primary-main);
}
.info-message-title {
  font-weight: 1.125rem;

  font-weight: bold;
}

.info-message-description {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.info-header {
  display: flex;
  gap: 0.25rem;
}
