.portfolio-div {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  gap: 2rem;
}

@media screen and (min-width: 1000px) {
  .portfolio-div {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
}
