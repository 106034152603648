.react-calendar {
  width: 100% !important;
  border: 0.25px solid var(--text) !important;
  font-size: 18px !important;
}

.unavailable-date {
  background-color: var(--grey-bg) !important;
  color: var(--text) !important;
  text-decoration: line-through;
}

.available-date {
  background-color: white !important;
  color: var(--primary-main) !important;
  border: 0.5px solid var(--primary-main);
}

.selected-date {
  background-color: var(--primary-main) !important;
  color: white !important;
}

.react-calendar__tile {
  font-family: 'Cabin Condensed' !important;
  font-size: 1.125rem !important;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white !important;
  border-bottom: none !important;
  color: var(--primary-main) !important;
}

.react-calendar__navigation button {
  color: var(--primary-main) !important;
  cursor: pointer;
  font-family: 'Cabin Condensed' !important;
  font-size: 1.25rem;
  font-weight: bold;
  border: none !important;
}

.react-calendar__month-view__weekdays {
  border-bottom: none;
  font-family: 'Cabin Condensed' !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: var(--text) !important;
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

.error-msg {
  display: flex;
  width: 100%;
  font-size: 20px;
  color: var(--error)
}