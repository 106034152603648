:root {
  --primary-light: #d0e7d2;
  --primary-main: #79ac78;
  --primary-dark: #618264;
  --primary-600: #b0d9b1;
  --text: #838383;
  --beige: #cba163;
  --paper-bg: #f6f6f6;
  --menu-bg: #879e88;
  --grey-bg: #f6f6f6;
  --primary-light-bg: #d0e7d2;
  --grey-border: rgba(131, 131, 131, 0.25);
  --error: #c21f3a;
  --error-bg: rgb(194, 31, 58, 0.1);
  background: var(--primary-light);
  --info: rgb(94, 164, 255);
  --info-bg: rgba(94, 164, 255, 0.1);
  --success: #184709;
  --success-bg: rgb(107, 248, 60, 0.1);
  --paper: drop-shadow(1px 1px 10px var(--grey-border));
  --gradient: linear-gradient(to right top, #79ac78, #8eab6c, #a3a964, #b8a561, #cba163); 
}
.App {
  text-align: center;
  color: var(--text);
}

.reservation-root {
  max-width: 37.5rem;
  background-color: white;
  height: 100vh;
  overflow: auto;
}

.main-root {
  max-width: 87.5rem;
  margin: 0 auto;
  color: var(--text);
  height: 100%;
}

a {
  color: var(--primary-main);
  font-weight: bold;
  cursor: pointer;
}

.a-h3 {
  font-size: 1.125rem;
}

.heading {
  font-size: 2rem;
  text-align: left;
  font-weight: bold;
}

.heading3 {
  font-size: 1.25rem;
  font-weight: bold;
}

.section-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media screen and (min-width: 700px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    /* background-color: red; */
  }
  /* @media screen and (min-width: 56.25rem) {   
    flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  color: var(--text);
 
  width: 100%;
  } */
}
