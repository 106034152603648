.form-container-green {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-light);
  gap: 0.5rem;
}
.item-title {
  font-weight: bold;
}

.detail-div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actions-div {
  display: flex;
  flex-direction: column;
}
.dashboard-detail {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: white;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.charges {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.paid-items {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid var(--grey-border);
}

.item-description {
  display: flex;
  flex-direction: column;
}

.item-cost {
  font-weight: bold;
  align-self: flex-end;
}

.dash-btn-group {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.error-chip {
  color: var(--error);
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  background-color: rgba(194, 31, 58, 0.2);
  border: 1px solid var(--error);
  border-radius: 1rem;
}

#customers {
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th {
  padding-bottom: 12px;
  text-align: left;
}

#totals {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 14px;
  align-self: flex-end;
  padding: 1rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  text-align: left;
}

.payments-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  text-align: right;
  padding: 0rem 1rem;
  & span {
    width: 100%;
  }
}

.payments-table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.col-left {
  text-align: left;
}
.col {
  align-self: left;
}

#heading {
  font-weight: bold;
}
#grand {
  font-weight: bold;
}

#remaining {
  color: var(--primary-main);
  font-weight: bold;
}

.heading4 {
  font-weight: bold;
}

.personal-info {
  display: flex;
  flex-direction: column;
}

.col1 {
  font-size: 18px;
}
.col2 {
  font-size: 18px;
}
