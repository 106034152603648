.circle-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    
  }
  
  .loadingCircle {
    display: block;
    width: .75rem;
    height: .75rem;
    background-color: var(--primary-light);
    border-radius: 50%;
  }