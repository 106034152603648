.reservation-app-bar {
    display: flex;
    /* width: 100%;  */
    align-items: center;
    justify-content: space-between;
    padding: 2rem 2rem; 
    background-color: white;
    /* border-bottom: 1px solid var(--primary-light); */
}

.reservation-details-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-main); 
    font-size: 1rem;
}

.logo-container {
    max-width: 12.5rem;
    & img {
        height: 100%;
        width: 100%;
    }
}

.left-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.booking-carousol {
    width: 100%
}

.back-icon {
    cursor: pointer;
    transition: 0.2 ease-in-out;
}