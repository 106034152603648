.guests-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.count-controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
