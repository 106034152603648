.confirmed-div {
  text-align: left;
  background-color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 2rem;
}

@media screen and (min-width: 1000px) {
  .confirmed-div {
    flex-direction: row;
    gap: 3rem;
    align-items: space-between;
    justify-content: flex-start;
  }
}
.left-view {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.right-view {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.confirmed-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reservation {
  background-color: white;
  filter: var(--paper);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1.5rem;
}

.reservation-recap {
  display: flex;
  flex-direction: column;
}
.whats-next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 2rem;
}

.next-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.item-title {
  font-size: 18px;
  font-weight: bold;
}

.order-details {
  display: flex;
  flex-direction: column;
}

.confirmed-logo {
  max-width: 300px;
  & img {
    height: 100%;
    width: 100%;
  }
}

.logo-div-confirmed {
  max-width: 250px;
  & img {
    height: 100%;
    width: 100%;
  }
}
