.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem;
}

.quick-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.social-media-container {
  display: flex;
  flex-direction: column;
}

.drawer-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.policy-drawer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.footer-logo-container {
  max-width: 12.5rem;

  & img {
    width: 100%;
    height: 100%;
  }
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright {
  /* padding: 1rem; */
  color: var(--text);

  & a {
    color: var(--primary-main);
    text-decoration: none;
  }
}
