.advanced-booking {
    color: var(--text);
    gap: 1rem; 
    width: 100%;
}

.need-help {
    display: flex;
    margin-top: 2rem; 
    width: 100%;
    align-items: center;
    justify-content: center;
}