.gallery {
  display: flex;
}

@media screen and (min-width: 1000px) {
  .gallery {
    max-width: 50%;
  }
}
/* 
.carousol {
  width: 200px;
} */
