.package {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 400px;
  border-radius: 20px;
  padding: 1rem;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.package.selected {
  border: 2px solid var(--primary-main);
}

.package-img {
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
  }
}

.whats-included-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
}

.menus-container {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu-view {
  font-size: 18px;
  color: var(--primary-main);
  font-weight: bold;
}

.package-inclusion {
  display: flex;
  gap: 0.5rem;
}
