.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex: 1;
}

.form-btn-group {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.error-caption {
  color: var(--error);
}

.address-input {
  font-size: 1.25rem;
  color: var(--text);
  padding: 1rem 1rem;
  width: 90%;
  border: 1px solid var(--grey-border);
}

.outdoor-radios {
  display: flex;
  flex-direction: column;
  width: 100%;
}
