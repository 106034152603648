.policy {
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
}

.policy-item {
    display: flex;
    flex-direction: column;
    gap: .5rem; 
}
.policy-title {
    font-weight: bold;
    text-transform: uppercase;
}
