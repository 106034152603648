.services-container {
  padding: 3rem 3rem;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.services-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.header-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.service-chips-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.packages-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.all-packages-container {
  display: none;
}

@media screen and (min-width: 768px) {
  .service-chips-div {
    flex-direction: row;
    gap: 1rem;
  }
}
/* Show all-packages-container on large screens */
@media screen and (min-width: 1000px) {
  .all-packages-container {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    justify-content: space-between;
    /* background-image: linear-gradient(to right top, #79ac78, #8eab6c, #a3a964, #b8a561, #cba163); */
  }
  .packages-container {
    display: none;
  }
}
