.howItWorks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 3rem;
  color: var(--text);
  gap: 2rem;
}

.stock-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: .5rem;
}

.how-it-works-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: white;
  padding: 1rem;
  filter: var(--paper);
}


.stepTile {
  flex: 1 1 30%; /* Flex-grow, flex-shrink, and base width for equal sizing */
  margin: 10px; /* Optional: spacing around each tile */
  box-sizing: border-box;
}

.stepInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 0.25rem;
}

.step {
  background-color: var(--primary-light);
  color: var(--primary-main);
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.steps-header {
  color: var(--text);
  font-size: 1.25rem;
  font-weight: bold;
}

.section-stock {
  max-width: 100%;
  position: relative;
  width: 100%; /* Ensure text is above the gradient */
  background-image: url('../resources/stock/bottles.png');
  background-size: cover; /* Cover the entire div */
  background-position: center;
  width: 100%;
  height: 250px;
}

.section-stock::before {
  content: ''; /* Necessary for the pseudo-element */
  position: absolute; /* Position it absolutely within the div */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  bottom: 0; /* Stretch to the bottom */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.1)); /* White gradient */
  z-index: 1; /* Ensure it is above the background but below content */
}

.header-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.stock-title {
  font-size: 2rem;
  font-weight: bold;
  z-index: 999;
}

.stock-description {
  font-size: 1.25rem;
  z-index: 999;
}
.consult-btn {
  display: none;
}

.need-to-know {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-image: url('../resources/stock/pad.svg');
  background-size: cover; /* Cover the entire div */
  background-position: center;
}
.highlights {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  gap: 1rem;
  background-color: white;
}

.highlight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 0.5rem;
  padding: 0.5rem;
}

.highlight-text {
  display: flex;
  flex-direction: column;
}

.highlight-heading {
  font-size: 18px;
  font-weight: bold;
}

.h1 {
  font-size: 1.25rem;
}

.iphone-stock {
  max-width: 400px;
  margin: 0 auto;
  flex: 1;
  & img {
    height: 100%;
    width: 100%;
  }
}

.btn-option {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 700px) {
  .content {
    flex-direction: row;
    flex-wrap: wrap; /* Ensure tiles wrap if necessary */
    justify-content: space-between;
  }

  .howItWorks {
    flex-direction: row;
  }

  .highlights {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .highlight {
    flex: 1;
    max-width: 500px;
    box-sizing: border-box;
  }
  .consult-btn {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}