.form {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}
.form-div {
  display: flex;
  flex-direction: column;
  background: white;
  filter: var(--paper);
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
}
.form-h3 {
  font-size: 20px;
  font-weight: bold;
}

.form-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
}

.ga-based {
  width: 100%;
  background-color: white;
  filter: drop-shadow(0px 1px 5px var(--grey-border));
  /* background-image: url('../../resources/stock/cheers.png'); */
  background: var(--gradient);
  background-size: cover; /* Cover the entire div */
  background-position: center;
  height: 250px;
}

.ga-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 2rem;
  height: 100%;
}

.form-inputs-div {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 2rem;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.form-container-div {
  display: flex;
}

.form-label {
  font-size: 1.125rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
