.event-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: .5rem;
}
.icon-detail {
    display: flex; 
    align-items:flex-start;
    justify-content: center;
    gap: .5rem;

}

.detail {
    display: flex; 
    align-items: flex-start;
    flex-direction: column;
    gap: .5rem;
    width: 100%;

}

.detail-header {
    color: var(--primary-main);
}

