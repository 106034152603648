.book-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    gap: 2rem;
    color: var(--text);
    height: 100%;
}

.logo-container {
    max-width: 15.625rem;
    align-self: center;
    & img {
        height: 100%; 
        width: 100%;
    }
}

.instructions-container {
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
}

.booking-btn-group {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
     & button {
        width: 100%;
     }
}

.route-link {
    width: 100%;
}

.estimated-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px; 
}

.error-message {
    background-color: rgba(194, 31, 58, .2);
    color: var(--error);
    border: 1px solid var(--error); 
    padding: 1rem;
}