.est-drawer {
    display: flex; 
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
}
.estimate-header {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

