#error-page {
  color: var(--text);
  background-color: white;
  height: 100vh;
}

.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    background-color: white;
    padding: 1rem;
}


.error-draw {
  max-width: 250px;
  & img {
    height: 100%;
    width: 100%;
  }
}

.error-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem; 
  padding: 1rem;
}

.error-title {
  font-size: 2rem;
}

.error-description {
  font-size: 1.25rem;
  text-align: center;
}

.error-logo-container {
    max-width: 250px;
    & img {
        height: 100%;
        width: 100%;
    }

}