.appBar {
  background-color: white;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  z-index: 1000;
  padding: 1rem 3rem;
  border-bottom: 0.5px solid var(--grey-border);
  color: var(--text);
}

.appBar.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.appBar.visible {
  transform: translateY(0);
  opacity: 1;
}

.sideBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  padding: 3rem;
  align-items: center;
  z-index: 999;
  justify-content: flex-start;
  transition: 0.2s ease-in-out;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.extendedMenu {
  display: none;
}

.menuContainer {
  display: none;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .appBar {
    flex-direction: column;
  }
  .menuIcon {
    display: none;
  }

  .menuContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .extendedMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.menuIcon {
  cursor: pointer;
}
.logoContainer {
  max-width: 250px;
  cursor: pointer;
  & img {
    height: 100%;
    width: 100%;
  }
}
.sideBarLogo {
  max-width: 250px;
  & img {
    height: 100%;
    width: 100%;
  }
}

.side-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
}

.active {
  cursor: pointer;
  transition: 0.2s ease-in;
  color: var(--primary-main) !important;
  font-weight: bold;
  font-size: 1.25rem;
}

.inactive {
  color: var(--text);
  cursor: pointer;
  font-size: 1.25rem;
  &:hover {
    transition: 0.2s ease-in;
  }
}

.app-bar-btns {
  display: flex;
  gap: 0.5rem;
}
