.due-now {
  display: flex;
  flex-direction: column;
}

.methods-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.payment-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.payment-subtotal-div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.pay-method {
  font-size: 1.25rem;
  color: var(--primay-main);
}
