.subtotal-dropdown {
  border: 0.5px solid var(--text);
  padding: 1rem;
  border-radius: 10px;
}

.subtotal {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: .5rem;
}

.due {
  color: var(--primary-main);
}

.cost-breakdown {
  display: flex;
}

.line-items {
    padding: 1rem; 
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: flex-start;
    justify-content: space-between;
}

.line-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}
.quote-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: var(--grey-bg);
  padding: 1rem;
}
.quote-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.fee-type {
  font-size: 20px;
}

.items-list {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.estimate-btn-grp {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.three-dots {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.line-item-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-name {
  font-weight: bold;
}

.item-variation {
  padding: 0 1rem;
}